@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  font-weight: 200;
  font-style: normal;
  src: url("./fonts/Inter-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: 200;
  font-style: normal;
  src: url("./fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: 900;
  font-style: normal;
  src: url("./fonts/Inter-ExtraBold.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
